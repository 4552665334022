<template>
	<section class="row featured-content channel-rail movies">
		<template v-if="items.length > 0">
			<Slider
				:data="items"
				:showactive="true"
				:channelselector="true"
				:channelid="selectedChannel.key"
				:initialposition="sliderInitialPosition"
			>
				<template slot-scope="itemslider">
					<div
						class="slider-item channel-item"
						tabindex="-1"
						:class="{ active: selectedChannel.key === null }"
						v-on:click.prevent="
							$emit('selectProvider', null);
							selectChannel(null);
						"
					>
						<p>VER TODAS</p>
					</div>
					<div
						v-for="(item, index) of itemslider.data"
						:key="item.channel.id"
						class="slider-item channel-item"
						:class="{ active: selectedChannel.key === item.channel.id }"
						:id="'provider_img_' + index"
					>
						<a
							v-on:click.prevent="
								$emit('selectProvider', item.channel.id);
								selectChannel(item.channel.id);
							"
						>
							<figure>
								<img
									:src="item.channel.logo"
									:alt="item.channel.name"
									class="channel-logo"
									@error="hideImage('provider_img_' + index)"
								/>
								<figcaption>
									{{ item.channel.name }}
								</figcaption>
							</figure>
						</a>
					</div>
				</template>
			</Slider>
		</template>
	</section>
</template>
<script>

export default {
	name: "FilterProvider",

	components: {
		Slider: () => import("@/components/Slider.vue"),
	},

	props: {
		items: {
			type: Array,
			required: true,
			default: [],
		},
	},
	data() {
		return {
			filters: [],
			channels: [],
			selectedChannel: {
				key: null,
				hoy: [],
				ayer: [],
			},
		};
	},
	methods: {
		selectChannel(id) {
			this.selectedChannel.key = id;
		},
		hideImage(item) {
			if (item != null) {
				document.querySelector("#" + item).style.display = "none";
			}
		},
	},
	computed: {
		sliderInitialPosition() {
			const idSelectedChannel = this.selectedChannel.key;
			return this.items.findIndex((item) => item.channel.id === idSelectedChannel);
		},
	},
};
</script>
